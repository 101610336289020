<template>
  <div id="comments" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <div class="columns">
        <div class="column is-10 is-offset-1">
          <b-table :data="comments" hoverable>
            <b-table-column
              field="#"
              label="#"
              width="40"
              numeric
              v-slot="props"
            >
              {{ comments.indexOf(props.row) + 1 }}
            </b-table-column>

            <b-table-column label="Comment" v-slot="props">{{
              props.row.description
            }}</b-table-column>

            <b-table-column label="Actions" width="160" v-slot="props">
              <action-buttons
                @initialize-info="initializeInfo(props.row)"
                @initialize-update="initializeUpdate(props.row)"
                @initialize-remove="initializeRemove(props.row)"
              />
            </b-table-column>
          </b-table>
        </div>
      </div>
    </template>
    <!-- Delete -->
    <app-modal
      id="remove-comment"
      context="remove"
      @remove="remove"
      :show-modal.sync="isModalOpen"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag'
import COMMENTS from '../../../graphql/comment/Comments.gql'
import { crudNotification } from '../../assets/js/notification'

export default {
  name: 'comments',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Comments',
      isActive: false,
      comments: [],
      id: null,
      context: 'remove',
    }
  },
  methods: {
    initializeInfo(comment) {
      this.$router.push(`/school/${this.schoolId}/comment_info/${comment.id}`)
    },
    initializeUpdate(comment) {
      this.$router.push(`/school/${this.schoolId}/comment/${comment.id}`)
    },
    initializeRemove(comment) {
      this.id = parseInt(comment.id)
      this.openModal()
    },
    remove() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteComment($id: Int!) {
              deleteComment(input: { id: $id }) {
                comment {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: this.id,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.deleteComment.errors,
            'Successfully deleted.'
          ).then(() => {
            this.closeModal()
            this.$apollo.queries.comments.refetch()
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'New',
        route: `/school/${this.$route.params.school_id}/comment/new`,
      },
    ])

    this.$apollo.addSmartQuery('comments', {
      query: COMMENTS,
      variables: {
        schoolId: this.schoolId,
      },
    })

    this.$apollo.queries.comments.refetch()
  },
}
</script>
